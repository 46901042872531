import { MasterUser } from 'api/master/getUser';
import clsx from 'clsx';
import useSelectedUserStore from 'store/master/selectedUserStore';
import { Locale } from 'types/types';

type Props = {
  user: MasterUser;
};

export default function UserItem({ user }: Props) {
  const { setSelectedUser } = useSelectedUserStore();

  return (
    <div
      id={`user-item-${user.id}`}
      className={styles}
      onClick={() => {
        setSelectedUser(user);
      }}
    >
      <div
        id={`user-item-${user.id}-number`}
        className="w-[50px] text-sm text-subText max-sm:text-xs"
      >
        {user.id}
      </div>
      <div
        id={`user-item-${user.id}-name`}
        className="w-[100px] font-bold text-sm truncate max-sm:text-xs text-left"
      >
        {user.user}
      </div>

      <div
        id={`user-item-${user.id}-address`}
        className="w-[80px] text-sm text-subText text-center "
      >
        {user.access}
      </div>
      <div
        id={`user-item-${user.id}-country`}
        className="w-[80px] text-sm text-subText text-center"
      >
        {user.selected_id}
      </div>
      <div
        id={`user-item-${user.id}-email`}
        className="w-[200px] text-sm text-subText truncate max-sm:text-xs"
      >
        {user.email}
      </div>
      <div
        id={`user-item-${user.id}-language`}
        className="w-[30px] font-semibold text-xs text-center"
      >
        {LOCALES[user.language - 1]}
      </div>
      <div
        id={`user-item-${user.id}-language`}
        className="w-[30px] text-xs text-center "
      >
        {`${user.auth}(${getAuthString(user.auth)})`}
      </div>
    </div>
  );
}
// style
const styles = clsx(
  'flex gap-x-[11px] px-15px py-3 items-center text-center',
  'hover:bg-subLine',
);

//utils
const getAuthString = (auth: number) => {
  switch (auth) {
    case 0:
      return 'Guest';
    case 1:
      return 'Manager';
    case 2:
      return 'Owner';
    case 3:
      return 'Admin';
    case 4:
      return 'Master';
    default:
      return 'Unknown';
  }
};
const LOCALES: Locale[] = ['KR', 'ENG', 'VNM'];
