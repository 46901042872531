import useSelectedUserStore from 'store/master/selectedUserStore';
import UserAddButton from '../UserAdd/UserAddButton';
import UserTable from './UserTable';
import Modal from 'components/common/Modal';
import UserEditContents from '../UserEdit/UserEditContents';
import AsyncBoundary from 'components/common/AsyncBoundary';

export default function UserList() {
  const { selectedUser, setSelectedUser } = useSelectedUserStore();
  const onClose = () => {
    setSelectedUser(null);
  };
  return (
    <div className="flex flex-col gap-y-[18px] w-[800px] max-[800px]:w-screen">
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-bold">회원관리</h1>
        <UserAddButton />
      </div>
      <UserTable />
      <Modal open={selectedUser !== null} onClose={onClose} closeButton={false}>
        <AsyncBoundary>
          {selectedUser && <UserEditContents userId={selectedUser?.id} />}
        </AsyncBoundary>
      </Modal>
    </div>
  );
}
