import FarmList from 'components/master/FarmList/FarmList';

export default function FarmListPage() {
  return (
    <div
      id="master-main-farm-list"
      className="flex flex-col items-center pt-7 max-[800px]:pt-0"
    >
      <FarmList />
    </div>
  );
}
