import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

// const MAX_RETRY_COUNT = 3;

const BASEURL =
  process.env.REACT_APP_PRODUCT_MODE === 'TEST'
    ? process.env.REACT_APP_TEST_SERVER_URL
    : process.env.REACT_APP_API_ROOT;

const axiosConfig: AxiosRequestConfig = {
  baseURL: BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};
const customAxios: AxiosInstance = axios.create(axiosConfig);

type RefreshResponse = {
  access_token: string;
};

async function refreshAccessToken() {
  const refreshToken = localStorage.getItem('refresh_token') || '';

  // access token이 만료되었을 때 사용할 새로운 Axios 인스턴스를 생성한다.
  const refreshAxiosConfig: AxiosRequestConfig = {
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      Authorization: `Bearer ${refreshToken}`,
    },
  };

  const refreshAxios: AxiosInstance = axios.create(refreshAxiosConfig);

  const response = await refreshAxios.post<RefreshResponse>(
    '/web/newAccessToken.php',
  );
  return response.data.access_token;
}

customAxios.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const accessToken = localStorage.getItem('access_token');
    if (config.headers && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

customAxios.interceptors.response.use(
  async (response) => response,
  async (error: AxiosError) => {
    // 만약 486 Unauthorized 오류가 발생한다면, access 토큰이 만료되었다고 간주할 수 있습니다.
    if (error.response?.status === 486) {
      const originalRequest = error.config;

      try {
        // refresh 토큰을 사용해서 새로운 액세스 토큰을 발급받습니다.
        const accessToken = await refreshAccessToken();

        // 로컬 스토리지에 새 토큰을 저장합니다.
        localStorage.setItem('access_token', accessToken);

        // 원래 요청에 실패했던 헤더를 업데이트합니다.
        if (originalRequest.headers && accessToken) {
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        // 원래 요청을 다시 시도합니다.
        return customAxios(originalRequest);
      } catch (refreshError) {
        // 리프레시 실패 시 사용자에게 로그인 페이지로 리디렉션하거나 에러 메시지를 표시합니다.
        console.error(refreshError);
        // 로그인 페이지로 리디렉션하는 등의 추가 로직을 여기에 작성합니다.
        throw refreshError;
      }
    }
    // 만약 401 에러가 발생한다면, refresh 토큰이 만료되었다고 간주할 수 있습니다.
    if (error.response?.status === 401) {
      throw error;
    }
    return Promise.reject(error);
  },
);
export default customAxios;
