import { MasterFarm } from 'api/master/getFarm';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
type Props = {
  farm: MasterFarm;
};
export default function FarmItem({ farm }: Props) {
  return (
    <Link to={`./${farm.id}`} id={`farm-item-${farm.id}`} className={styles}>
      <div
        id={`farm-item-${farm.id}-number`}
        className="w-[50px]  text-sm text-subText max-xs:text-xs"
      >
        {farm.id}
      </div>
      <div
        id={`farm-item-${farm.id}-name`}
        className="w-[170px] font-bold max-xs:text-xs"
      >
        {farm.farm_name}
      </div>
      <div
        id={`farm-item-${farm.id}-address`}
        className="w-[200px] text-sm text-subText line-clamp-2 max-xs:text-xs"
      >
        {farm.farm_address}
      </div>
      <div
        id={`farm-item-${farm.id}-country`}
        className="w-[80px] text-sm text-subText max-xs:text-xs"
      >
        {`${farm.access}번 서버`}
      </div>
    </Link>
  );
}

// style
const styles = clsx(
  'flex gap-x-11 px-15px py-3 items-center',
  'hover:bg-subLine',
);
