import { MAIN_GREEN, SUB_LINE } from 'constants/color';
import Select, { StylesConfig } from 'react-select';
import { Auth, isAuth } from 'types/types';
import { OptionType } from 'utils/select';

type Props = {
  selectedAuth?: Auth;
  onSelect: (value: Auth) => void;
};

export default function AuthSelect({ selectedAuth, onSelect }: Props) {
  const options: OptionType[] = [
    { value: `0`, label: '0 - Guest' },
    { value: `1`, label: '1 - Manager' },
    { value: `2`, label: '2 - Owner' },
    { value: `3`, label: '3 - Admin' },
    { value: `4`, label: '4 - Master' },
  ];
  return (
    <Select
      className="flex flex-1 h-full"
      styles={authSelectStyle}
      options={options}
      value={options.find((open) => open.value === `${selectedAuth}`)}
      onChange={(selectedOption) => {
        if (!selectedOption) return;
        const selectedAuth = Number(selectedOption.value);
        isAuth(selectedAuth) && onSelect(selectedAuth);
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
}
export const authSelectStyle: StylesConfig<OptionType, false> = {
  option: (provided) => ({
    ...provided,
    opacity: 0.8,
    // backgroundColor: state.isFocused ? colorMainGreen : undefined,
  }),
  control: (provided, state) => ({
    ...provided,
    flex: 1,
    borderColor: SUB_LINE,
    outlineColor: state.isFocused ? MAIN_GREEN : undefined,
    boxShadow: 'none',
    borderRadius: 6,
    height: 45,
    padding: '0 10px',
    ':hover': {
      outlineColor: SUB_LINE,
      borderColor: SUB_LINE,
    },
    ':focus': {
      borderColor: SUB_LINE,
      outlineColor: SUB_LINE,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#7D7B7B',
    fontSize: 14,
    fontWeight: 400,
  }),
  indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
};
