import FarmItems from './FarmItems';
import FarmPagination from './FarmPagination';

//관리자 페이지에서 농장 목록을 보여주는 컴포넌트
export default function FarmTable() {
  return (
    <div
      id="farm-table"
      className="rounded-md flex flex-col gap-y-[18px] shadow-main overflow-hidden pb-5"
    >
      <div>
        <div
          id="farm-table-header"
          className="flex py-3 gap-x-11 item-center px-15px bg-[#f0eff3] text-sm text-subText max-xs:text-xs whitespace-nowrap"
        >
          <div id="farm-table-header-number" className="w-[50px]">
            번호
          </div>
          <div id="farm-table-header-name" className="w-[170px]">
            농장명
          </div>
          <div id="farm-table-header-address" className="w-[200px]">
            농장주소
          </div>
          <div id="farm-table-header-country" className="w-[80px]">
            국가
          </div>
        </div>
        <FarmItems />
      </div>
      <FarmPagination />
    </div>
  );
}
