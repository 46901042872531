import UserItems from './UserItems';
import UserPagination from './UserPagination';

export default function UserTable() {
  return (
    <div
      id="user-table"
      className="rounded-md flex flex-col gap-y-[18px] shadow-main overflow-hidden pb-5"
    >
      <div>
        <div
          id="user-table-header"
          className="flex py-3 gap-x-[11px] item-center px-15px bg-[#f0eff3] text-sm text-subText max-sm:text-xs whitespace-nowrap"
        >
          <div id="user-table-header-number" className="w-[50px]">
            번호
          </div>
          <div id="user-table-header-name" className="w-[100px]">
            성함
          </div>

          <div id="user-table-header-access" className="w-[80px] text-center">
            서버번호
          </div>
          <div id="user-table-header-farm" className="w-[80px] text-center">
            농장정보
          </div>
          <div id="user-table-header-email" className="w-[200px]">
            이메일
          </div>
          <div id="user-table-header-language" className="w-[30px]">
            언어
          </div>
          <div id="user-table-header-auth" className="w-[30px]">
            권한
          </div>
        </div>

        <UserItems />
      </div>
      <UserPagination />
    </div>
  );
}
