import clsx from 'clsx';
import useFarmTypeStore from 'store/master/farmTypeStore';

export default function AddRackTypeForm() {
  const { racktypeInfo, setRacktypeInfo, isExistRackType } = useFarmTypeStore();

  return (
    <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-x-4 gap-y-1 max-[800px]:grid-cols-1 max-[800px]:grid-flow-row-dense">
      <div className="flex items-center gap-x-1">
        <label htmlFor="racktype-form-celltype" className={labelStyle}>
          곤충종류
        </label>
      </div>
      <div className="flex items-center gap-x-1">
        <label htmlFor="racktype-form-celltype" className={labelStyle}>
          라인개수
        </label>
        <input
          type="number"
          disabled={isExistRackType}
          value={racktypeInfo.lineCount}
          onChange={(e) => {
            const newLineCount = parseInt(e.target.value);
            setRacktypeInfo({
              ...racktypeInfo,
              lineCount: newLineCount,
            });
          }}
          className={inputStyle}
        ></input>
      </div>
      <div className="flex items-center gap-x-1">
        <label htmlFor="racktype-form-celltype" className={labelStyle}>
          라인당 블록
        </label>
        <input
          type="number"
          disabled={isExistRackType}
          className={inputStyle}
          value={racktypeInfo.blockCount}
          onChange={(e) => {
            const newBlockCount = parseInt(e.target.value);
            setRacktypeInfo({
              ...racktypeInfo,
              blockCount: newBlockCount,
            });
          }}
        ></input>
      </div>
      <div className="flex items-center gap-x-1">
        <label htmlFor="racktype-form-celltype" className={labelStyle}>
          박스 층 수
        </label>
        <input
          type="number"
          disabled={isExistRackType}
          className={inputStyle}
          value={racktypeInfo.floor}
          onChange={(e) => {
            const newFloor = parseInt(e.target.value);
            setRacktypeInfo({
              ...racktypeInfo,
              floor: newFloor,
            });
          }}
        ></input>
      </div>
    </div>
  );
}

//style
const labelStyle = clsx('text-sm text-subText min-w-[70px] whitespace-nowrap');
const inputStyle = clsx(
  'border-subLine border rounded-md px-2.5 py-3 flex-1',
  'focus:outline-none',
  'font-bold text-sm',
);
