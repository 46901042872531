import { useUserQuery } from 'hooks/reactQuery/master/usersQuery';
import AccessSelect from '../AccessSelect';
import useSelectedUserStore from 'store/master/selectedUserStore';
import UserEditButtons from './UserEditButtons';
import UserEditOrigin from './UserEditOrigin';
import useUserEditInfoStore from 'store/master/userEditInfoStore';
import AuthSelect from '../AuthSelect';
import { useEffect } from 'react';
import LanguageSelect from '../LanguageSelect';
type Props = {
  userId: number;
};
export default function UserEditContents({ userId }: Props) {
  const { data: user } = useUserQuery(userId);
  const { setSelectedUser } = useSelectedUserStore();
  const { setUserInfo, access, auth, userName, language } =
    useUserEditInfoStore();
  useEffect(() => {
    if (user) {
      setUserInfo({
        userName: user.user,
        farmNumber: user.selected_id,
        auth: user.auth,
        access: user.access,
        language: user.language,
      });
    }
  }, [setUserInfo, user]);
  if (!user)
    return <div className="h-20 flexCenter">사용자를 찾을 수 없습니다.</div>;
  const onClose = () => setSelectedUser(null);
  return (
    <div className="flex flex-col gap-y-4">
      <h3 className="text-lg font-bold">회원정보 수정</h3>
      <div className="flex flex-col gap-y-30px">
        <UserEditOrigin user={user} />
      </div>
      <div className="grid grid-cols-2 gap-2 max-[800px]:flex max-[800px]:flex-col">
        <div id="user-name" className="flex gap-x-2.5 items-center col-span-2">
          <label className={labelStyle}>성함</label>
          <input
            type="text"
            className={inputStyle}
            value={userName}
            onChange={(e) => setUserInfo({ userName: e.target.value })}
          />
        </div>
        <div id="user-auth" className="flex gap-x-2.5 items-center">
          <label className={labelStyle}>권한</label>
          <AuthSelect
            selectedAuth={auth}
            onSelect={(newAuth) => setUserInfo({ auth: newAuth })}
          />
        </div>
        <div id="user-language" className="flex gap-x-2.5 items-center">
          <label className={labelStyle}>언어</label>
          <LanguageSelect
            selectedLanguage={language}
            onSelect={(newLanguage) => setUserInfo({ language: newLanguage })}
          />
        </div>
        <div id="farm-number" className="flex gap-x-2.5 items-center">
          <label className={labelStyle}>농장번호</label>
          <input
            type="number"
            className={inputStyle}
            defaultValue={user.selected_id}
            onChange={(e) => {
              const newFarmNumber = parseInt(e.target.value);
              setUserInfo({ farmNumber: newFarmNumber });
            }}
          />
        </div>
        <div id="access-number" className="flex gap-x-2.5 items-center">
          <label className={labelStyle}>서버번호</label>
          <AccessSelect
            selectedAccess={access}
            onSelect={(newAccess) => setUserInfo({ access: newAccess })}
          />
        </div>
      </div>
      <UserEditButtons onClose={onClose} user={user} />
    </div>
  );
}

//style
const labelStyle = 'text-sm text-subText min-w-[80px]';
const inputStyle =
  'w-full h-8 border border-subLine rounded-md px-2 h-full py-2';
